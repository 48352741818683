const fieldList = [
  "id",
  "uid",
  "truckId",
  "deviceId",
  "violationCounts",
  "startTimestamp",
  "endTimestamp",
  "score",
];

class JobOperation {
  constructor(data) {
    for (var key of Object.keys(data)) {
      if (fieldList.includes(key)) {
        this[`_${key}`] = data[key];
      }
    }
  }

  get id () {
    return this._id;
  }

  get uid() {
    return this._uid;
  }

  get truckId () {
    return this._truckId;
  }

  get deviceId () {
    return this._deviceId;
  }

  get violationCounts() {
    return this._violationCounts;
  }

  get startTimestamp() {
    return this._startTimestamp;
  }

  get endTimestamp() {
    return this._endTimestamp;
  }

  get score() {
    return this._score;
  }

  // custom 
  get activeDate () {
    return new Date(new Date(this.startTimestamp).toDateString());
  }
}

export { JobOperation };
